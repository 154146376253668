import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import back from '../images/FirstComponent/FirstComponentBack.gif';

function ResetPassword() {
    const params = new URLSearchParams(location.search);
    const password = params.get('password');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://web.fundlumina.com/resetpasswordluminafund?password=${password}`);
                if (response.status === 200) {
                    alert("Password changed. Wait email with new password.");
                    navigate('/login');
                }
            } catch (error) {

                console.error("Failed to fetch user data:", error);
                navigate('/')
            }
        };

        fetchUserData();
    }, [password, navigate]);

    return (
        <div className='infinity-loading'>
            <img src={back} alt="Loading animation" />
        </div>
    );
}

export default ResetPassword;