export const MAIN_ROUTE = '/'
export const REG_ROUTE = '/regestration'
export const LOGIN_ROUTE = '/login'
export const FORGOT_ROUTE = '/forgotpassword'
export const RESET_ROUTE = '/resetpasswordlumina'

export const LICH_ROUTE = '/lk'

export const TRANS_ROUTE = '/lk/mytransactions'
export const INVEST_ROUTE = '/lk/myinvestments'
export const SETTINGS_ROUTE = '/lk/mysettings'
export const ACCOUNTS_ROUTE = '/lk/myaccounts'
export const DEPOSIT_ROUTE = '/lk/mydeposit'
export const REFERAL_ROUTE = '/lk/referalprogram'

